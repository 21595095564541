<template>
  <b-modal
    id="modal-tenant"
    ref="refModal"
    title="Khách hàng"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="itemLocal.individual"
                name="individual"
                :value="true"
                class="custom-control-primary"
              >
                Cá nhân
              </b-form-radio>

              <b-form-radio
                v-model="itemLocal.individual"
                name="individual"
                :value="false"
                class="custom-control-primary"
              >
                Tổ chức
              </b-form-radio>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              {{
                itemLocal.individual === true
                  ? 'Thông tin cá nhân:'
                  : 'Thông tin tổ chức:'
              }}
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="3">
            <validation-provider
              #default="validationContext"
              name="file-input-avatar"
              :rules="isTingTong ? 'required' : ''"
            >
              <image-view
                id="file-input-avatar"
                v-model="itemLocal.avatarFile"
                :required="isTingTong"
                :src="itemLocal.avatar"
                label="Ảnh đại diện"
                :instruction="null"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-if="itemLocal.individual"
            cols="3"
          >
            <validation-provider
              #default="validationContext"
              name="file-input-id-number-front"
              :rules="isTingTong ? 'required' : ''"
            >
              <image-view
                id="file-input-id-number-front"
                v-model="itemLocal.idNumberFrontImageFile"
                :required="isTingTong"
                :src="itemLocal.idNumberFrontImage"
                label="CCCD mặt trước"
                :instruction="null"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-if="itemLocal.individual"
            cols="3"
          >
            <validation-provider
              #default="validationContext"
              name="file-input-id-number-back"
              :rules="isTingTong ? 'required' : ''"
            >
              <image-view
                id="file-input-id-number-back"
                v-model="itemLocal.idNumberBackImageFile"
                :required="isTingTong"
                :src="itemLocal.idNumberBackImage"
                label="CCCD mặt sau"
                :instruction="null"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-if="itemLocal.individual"
            cols="3"
          >
            <image-view
              id="file-input-passport"
              v-model="itemLocal.passportImageFile"
              :src="itemLocal.passportImage"
              label="Hộ chiếu"
              :instruction="null"
            />
          </b-col>

          <b-col
            v-if="!itemLocal.individual"
            cols="3"
          >
            <image-view
              id="file-input-certificate"
              v-model="itemLocal.certificateOfBusinessRegistrationImageFile"
              :src="itemLocal.certificateOfBusinessRegistrationImage"
              label="Đăng ký kinh doanh"
              :instruction="null"
            />
          </b-col>
        </b-row>

        <div v-if="itemLocal.individual === true">

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Họ và tên"
                >
                  <template
                    v-slot:label
                  >
                    Họ và tên
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Nguyễn Nam Phong"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required"
              >
                <b-form-group
                  label="Số điện thoại"
                >
                  <template
                    v-slot:label
                  >
                    Số điện thoại
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="phone"
                    v-model="itemLocal.phone"
                    placeholder="0868987355"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="email"
                :rules="isTingTong ? 'email|required' : 'email'"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Email
                    <span
                      v-if="isTingTong"
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="email"
                    v-model="itemLocal.email"
                    placeholder="phongnn@resident.vn"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="birthday"
                :rules="isTingTong ? 'required' : ''"
              >
                <select-date
                  v-model="itemLocal.birthday"
                  :required="isTingTong"
                  :default-value="itemLocal.birthday"
                  label="Ngày sinh"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>

            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="gender"
                :rules="isTingTong ? 'required' : ''"
              >
                <select-gender
                  v-model="itemLocal.gender"
                  :required="isTingTong"
                  :default-value="itemLocal.gender"
                  label="Giới tính"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>

            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="idNumber"
                :rules="isTingTong ? 'required' : ''"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    CMND/CCCD
                    <span
                      v-if="isTingTong"
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="idNumber"
                    v-model="itemLocal.idNumber"
                    placeholder="174850033"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>
          <b-row>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="issueDate"
                :rules="isTingTong ? 'required' : ''"
              >
                <select-date
                  v-model="itemLocal.issueDate"
                  required
                  :default-value="itemLocal.issueDate"
                  label="Ngày cấp"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>

            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="placeOfIssue"
                :rules="isTingTong ? 'required' : ''"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Nơi cấp CMND/CCCD
                    <span
                      v-if="isTingTong"
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="placeOfIssue"
                    v-model="itemLocal.placeOfIssue"
                    placeholder="Cục cảnh sát"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <select-province
                v-model="itemLocal.province"
                :default-value="itemLocal.province"
              />
            </b-col>
            <b-col md="6">
              <select-district
                v-model="itemLocal.district"
                :default-value="itemLocal.district"
                :province="itemLocal.province"
              />
            </b-col>
            <b-col md="6">
              <select-ward
                v-model="itemLocal.ward"
                :district="itemLocal.district"
                :default-value="itemLocal.ward"
              />
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Địa chỉ chi tiết"
              >
                <b-form-input
                  id="address"
                  v-model="itemLocal.address"
                  placeholder="91 Nguyễn Chí Thanh"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Số tài khoản"
              >
                <b-form-input
                  id="bankAccountNumber"
                  v-model="itemLocal.bankAccountNumber"
                  placeholder=""
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Ngân hàng"
              >
                <b-form-input
                  id="bankAccountName"
                  v-model="itemLocal.bankAccountName"
                  placeholder=""
                  trim
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Nghề nghiệp"
              >
                <b-form-input
                  id="job"
                  v-model="itemLocal.job"
                  placeholder="Sinh viên"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nơi làm việc"
              >
                <b-form-input
                  id="workplace"
                  v-model="itemLocal.workplace"
                  placeholder="Đại học Bách Khoa"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Người liên lạc"
              >
                <b-form-input
                  id="contactPersonName"
                  v-model="itemLocal.contactPersonName"
                  placeholder="Nguyễn Văn A"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="SĐT người liên lạc"
              >
                <b-form-input
                  id="contactPersonPhone"
                  v-model="itemLocal.contactPersonPhone"
                  placeholder="0868123456"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                label="Mã vân tay cửa ra vào"
              >
                <b-form-input
                  id="fingerPrintCode"
                  v-model="itemLocal.fingerPrintCode"
                  placeholder="68"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="itemLocal.foreign"
                  name="check-button"
                  switch
                  inline
                >
                  Khách nước ngoài
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="itemLocal.foreign === true">
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Quốc tịch"
                rules=""
              >
                <select-country
                  v-model="itemLocal.nationality"
                  :default-value="itemLocal.nationality"
                  label="Quốc tịch"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="passportNumber"
                rules=""
              >
                <b-form-group
                  label="Số hộ chiếu"
                >
                  <b-form-input
                    id="passportNumber"
                    v-model="itemLocal.passportNumber"
                    placeholder="C5006886"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="passportType"
                rules=""
              >
                <b-form-group
                  label="Loại hộ chiếu"
                >
                  <b-form-input
                    id="passportType"
                    v-model="itemLocal.passportType"
                    placeholder="P"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Ngày hết hạn"
                rules="required"
              >
                <select-date
                  v-model="itemLocal.dateOfExpiry"
                  :default-value="itemLocal.dateOfExpiry"
                  label="Ngày hết hạn"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <!-- Company -->
        <div v-else>

          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Tên công ty/tổ chức"
                >
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Cty Odoor Việt Nam"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required"
              >
                <b-form-group
                  label="Số điện thoại"
                >
                  <b-form-input
                    id="phone"
                    v-model="itemLocal.phone"
                    placeholder="0868987355"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="email"
                rules="email"
              >
                <b-form-group
                  label="Email"
                >
                  <b-form-input
                    id="email"
                    v-model="itemLocal.email"
                    placeholder="phongnn@resident.vn"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="businessCode"
                rules=""
              >
                <b-form-group
                  label="Mã số doanh nghiệp"
                >
                  <b-form-input
                    id="businessCode"
                    v-model="itemLocal.businessCode"
                    placeholder="0109154368"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Người đại diện"
              >
                <b-form-input
                  id="contactPersonName"
                  v-model="itemLocal.contactPersonName"
                  placeholder="Nguyễn Văn A"
                  trim
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="SĐT người đại diện"
              >
                <b-form-input
                  id="contactPersonPhone"
                  v-model="itemLocal.contactPersonPhone"
                  placeholder="0868123456"
                  trim
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="province"
                rules=""
              >
                <select-province
                  v-model="itemLocal.province"
                  :default-value="itemLocal.province"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="district"
                rules=""
              >
                <select-district
                  v-model="itemLocal.district"
                  :default-value="itemLocal.district"
                  :province="itemLocal.province"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="ward"
                rules=""
              >
                <select-ward
                  v-model="itemLocal.ward"
                  :default-value="itemLocal.ward"
                  :district="itemLocal.district"
                  :state="getValidationState(validationContext)"
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="address"
                rules=""
              >
                <b-form-group
                  label="Địa chỉ chi tiết"
                >
                  <b-form-input
                    id="address"
                    v-model="itemLocal.address"
                    placeholder="91 Nguyễn Chí Thanh"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Ghi chú">
              <b-form-textarea
                id="note"
                v-model="itemLocal.note"
                placeholder="Ghi chú"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary mb-1">
              Thông tin xe:
            </div>
          </b-col>
        </b-row>
        <b-row
          v-for="(eachVehicle, index) in itemLocal.vehicles"
          :id="eachVehicle.id"
          :key="eachVehicle.id"
          ref="row"
        >
          <b-col cols="3">
            <validation-provider
              #default="validationContext"
              :name="`type-${index}`"
              rules="required"
            >
              <select-vehicle-type
                v-model="eachVehicle.type"
                required
                :default-value="eachVehicle.type"
                label="Loại phương tiện"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="validationContext"
              :name="`name-${index}`"
              rules="required"
            >
              <b-form-group
                label="Tên dòng xe"
              >
                <template
                  v-slot:label
                >
                  Tên dòng xe
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  :id="`name-${index}`"
                  v-model="eachVehicle.name"
                  placeholder="Honda Airblade"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="3">
            <validation-provider
              #default="validationContext"
              :name="`licensePlate-${index}`"
              rules="required"
            >
              <b-form-group
                label="Biển số xe"
              >
                <template
                  v-slot:label
                >
                  Biển số xe
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  :id="`licensePlate-${index}`"
                  v-model="eachVehicle.licensePlate"
                  placeholder="29T2-686.68"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <b-col
            cols="6"
            sm="5"
          >
            <validation-provider
              #default="validationContext"
              :name="`quantity-${item.id}`"
              rules="required"
            >
              <number-input
                v-model="item.quantity"
                :label="`Định mức ${index + 1}`"
                placeholder="50"
                :class="['input-number-format',{error:validationContext.errors[0] }]"
                :default-value="item.quantity"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col> -->

          <b-col
            cols="3"
            sm="2"
            class="px-0 mt-sm-2"
          >
            <b-button
              variant="outline-danger"
              class="btn px-1 btn-outline-danger ml-1 ml-sm-auto float-sm-right mr-sm-1 w-90 min-w-75"
              @click="removeVehicle(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Xoá</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <div class="d-flex">
          <b-button
            variant="outline-warning"
            class="w-175"
            @click="addVehicle"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Thêm xe</span>
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadio,
  BFormCheckbox,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectGender from '@/views/components/SelectGender.vue';
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import SelectCountry from '@/views/components/SelectCountry.vue';
import SelectDate from '@/views/components/SelectDate.vue';
import ImageView from '@/views/components/ImageView.vue';
import SelectVehicleType from '@/views/components/SelectVehicleType.vue';
import useTenantModal from './useTenantModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BButton,
    SelectVehicleType,
    SelectGender,
    SelectProvince,
    SelectDistrict,
    SelectWard,
    SelectCountry,
    SelectDate,
    ImageView,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      addVehicle,
      onSubmit,
      removeVehicle,
      isTingTong,
    } = useTenantModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      isTingTong,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      addVehicle,
      removeVehicle,
    };
  },
};
</script>
