<template>
  <b-modal
    id="modal-tenant-detail"
    ref="refModalTenantDetail"
    title="Thông tin khách hàng"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-list-group class="">
        <b-list-group-item v-if="itemLocal.avatar">
          <b-row>
            <b-col class="font-weight-bolder">
              Ảnh đại diện:
            </b-col>
            <b-col class="">
              <avatar-view
                :src="itemLocal.avatar"
              />
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Họ tên:
            </b-col>
            <b-col class="">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Số điện thoại:
            </b-col>
            <b-col class="">
              {{ itemLocal.phone }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.email">
          <b-row>
            <b-col class="font-weight-bolder">
              Email:
            </b-col>
            <b-col class="">
              {{ itemLocal.email }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.birthday">
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày sinh:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.birthday) }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item v-if="itemLocal.gender">
          <b-row>
            <b-col class="font-weight-bolder">
              Giới tính:
            </b-col>
            <b-col class="">
              {{ itemLocal.gender.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              CMND/CCCD:
            </b-col>
            <b-col class="">
              {{ itemLocal.idNumber }} <span v-if="itemLocal.issueDate">cấp ngày: {{ parseDateToString(itemLocal.issueDate) }}</span>  <span v-if="itemLocal.placeOfIssue">tại: {{ itemLocal.placeOfIssue }}</span>
              <avatar-view
                v-if="itemLocal.idNumberFrontImage"
                class="my-1"
                :src="itemLocal.idNumberFrontImage"
              />
              <avatar-view
                v-if="itemLocal.idNumberBackImage"
                :src="itemLocal.idNumberBackImage"
              />
              <avatar-view
                v-if="itemLocal.passportImage"
                :src="itemLocal.passportImage"
              />
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.fullAddress">
          <b-row>
            <b-col class="font-weight-bolder">
              Địa chỉ:
            </b-col>
            <b-col class="">
              {{ itemLocal.fullAddress }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.job">
          <b-row>
            <b-col class="font-weight-bolder">
              Nghề nghiệp:
            </b-col>
            <b-col class="">
              {{ itemLocal.job }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.workplace">
          <b-row>
            <b-col class="font-weight-bolder">
              Nơi làm việc:
            </b-col>
            <b-col class="">
              {{ itemLocal.workplace }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.contactPersonName">
          <b-row>
            <b-col class="font-weight-bolder">
              Người liên lạc:
            </b-col>
            <b-col class="">
              {{ itemLocal.contactPersonName }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.contactPersonPhone">
          <b-row>
            <b-col class="font-weight-bolder">
              SĐT người liên lạc:
            </b-col>
            <b-col class="">
              {{ itemLocal.contactPersonPhone }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.nationality">
          <b-row>
            <b-col class="font-weight-bolder">
              Quốc tịch:
            </b-col>
            <b-col class="">
              {{ itemLocal.nationality.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.passportNumber">
          <b-row>
            <b-col class="font-weight-bolder">
              Số hộ chiếu:
            </b-col>
            <b-col class="">
              {{ itemLocal.passportNumber }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.passportType">
          <b-row>
            <b-col class="font-weight-bolder">
              Loại hộ chiếu:
            </b-col>
            <b-col class="">
              {{ itemLocal.passportType }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.dateOfExpiry">
          <b-row>
            <b-col class="font-weight-bolder">
              Ngày hết hạn hộ chiếu:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.dateOfExpiry) }}
            </b-col>
          </b-row>
        </b-list-group-item>

        <b-list-group-item v-if="itemLocal.note">
          <b-row>
            <b-col class="font-weight-bolder">
              Ghi chú:
            </b-col>
            <b-col class="">
              {{ itemLocal.note }}
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>

      <b-list-group
        v-if="itemLocal.individual"
        class="mt-1"
      >
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              Biểu mẫu:
            </b-col>
            <b-col class="">

              <b-link
                v-b-modal.modal-preview-pdf-ct01
                variant="flat-primary"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 font-medium-3"
                />
                <span class="">Mẫu CT01</span>
              </b-link>
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>
      <pdf-preview-modal :item="itemLocal" />

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import AvatarView from '@/views/components/AvatarView.vue';
import { parseDateToString } from '@/auth/utils';
import useTenantDetailModal from './useTenantDetailModal';
import PdfPreviewModal from '../pdf/PdfPreviewModal.vue';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    AvatarView,
    PdfPreviewModal,
  },
  props: {
    tenantId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
    } = useTenantDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      parseDateToString,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
