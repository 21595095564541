<template>
  <b-modal
    id="modal-delete-tenant"
    ref="refModalDeleteTenant"
    title="Xóa khách hàng"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-alert
        show
        variant="danger"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="AlertTriangleIcon"
          />
          <span class="ml-25">Vui lòng chọn khách hàng mới cho các phiếu thu, phiếu chi gắn với các khách hàng sắp bị xóa.</span>
        </div>
      </b-alert>
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Danh sách khách hàng bị xóa
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <tenant-table :data="tenants" />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Khách hàng tiếp nhận dữ liệu
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <select-tenant
              v-model="itemLocal.tenant"
              :enable-add="true"
              :ignore-ids="tenants.map(obj => obj.id)"
              :default-value="itemLocal.tenant"
              label="Chọn khách hàng"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BAlert,
} from 'bootstrap-vue';
import { ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectTenant from '@/views/components/SelectTenant.vue';
import useDeleteTenantModal from './useDeleteTenantModal';
import TenantTable from './TenantTable.vue';

export default {
  components: {
    BModal,
    BAlert,
    BRow,
    BCol,

    BForm,
    ValidationObserver,

    TenantTable,
    SelectTenant,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteTenant,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      tenants,
      onSubmit,
    } = useDeleteTenantModal(props, emit, refFormObserver);

    return {
      refModalDeleteTenant,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      tenants,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
