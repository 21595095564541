import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableBed } from '@/auth/utils';
import tenantStoreModule from '../tenantStoreModule';

export default function useTenantList() {
  const STORE_MODULE_NAME = 'tenant';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, tenantStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    individual: true,

    avatarFile: null,
    idNumberFrontImageFile: null,
    idNumberBackImageFile: null,
    passportImageFile: null,
    certificateOfBusinessRegistrationImageFile: null,

    avatar: null,
    idNumberFrontImage: null,
    idNumberBackImage: null,
    passportImage: null,
    certificateOfBusinessRegistrationImage: null,

    name: '',
    phone: '',
    email: '',
    birthday: null,
    gender: null,
    idNumber: '',
    issueDate: null,
    placeOfIssue: '',
    job: '',
    workplace: '',
    contactPersonName: '',
    contactPersonPhone: '',

    // address
    province: null,
    district: null,
    ward: null,
    address: '',

    // foreign
    foreign: false,
    nationality: null,
    passportNumber: '',
    passportType: '',
    dateOfExpiry: null,

    // company
    businessCode: '',

    note: '',
    vehicles: [],
  };
  const refPrint = ref(null);
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const printItem = ref({});
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'tenant') && !vm.$can('delete', 'tenant');

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '80px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: 'Họ tên',
      field: 'name',
    },
    {
      label: 'SĐT',
      field: 'phone',
    },
    {
      label: 'Ngày sinh',
      field: 'birthday',
      width: '120px',
    },
    {
      label: 'CMND/CCCD',
      field: 'idNumber',
    },
    {
      label: 'Nơi thường trú',
      field: 'fullAddress',
    },

  ];
  const rows = ref([]);
  const analytic = ref(null);
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const status = ref({ title: 'Đang ở', id: 0, value: 0 });
  const deleteItems = ref([]);
  const foreign = ref(null);
  const individual = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: { status: 0 },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchTenants = () => {
    isLoading.value = true;
    store
      .dispatch('tenant/fetchTenants', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = true;
    store
      .dispatch('tenant/fetchAnalytics', serverParams.value)
      .then(response => {
        analytic.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchTenants();
    fetchAnalytics();
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('tenant/importTenants', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('tenant/exportTenants', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteTenants = tenants => {
    store
      .dispatch('tenant/deleteTenants', {
        ids: tenants.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = {
      ...val,
      avatarFile: null,
      idNumberFrontImageFile: null,
      idNumberBackImageFile: null,
      passportImageFile: null,
      certificateOfBusinessRegistrationImageFile: null,
    };
  };

  const onPrint = val => {
    try {
      store
        .dispatch('tenant/getResidenceInformation', val.id)
        .then(response => {
          printItem.value = response.data;
        })
        .then(() => {
          refPrint.value.generatePdf();
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    } catch (_error) {
      //
    }
  };

  const onDeleteTenant = val => {
    deleteItems.value = val && val.id > 0 ? [val] : selectedRows.value;
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(bed, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.bedId = val.id;
    } else {
      delete filter.bedId;
    }
    updateParams({ filter });
  });
  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.value;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(foreign, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.foreign = val.value;
    } else {
      delete filter.foreign;
    }
    updateParams({ filter });
  });
  watch(individual, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.individual = val.value;
    } else {
      delete filter.individual;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    analytic,
    item,
    deleteItems,
    printItem,
    columns,
    rows,
    apartment,
    room,
    bed,
    status,
    foreign,
    individual,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    refPrint,
    fetchData,
    fetchTenants,
    deleteTenants,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onPrint,
    importData,
    exportData,
    onDeleteTenant,
    resolveColWidthIfDisableBed,
  };
}
